import { render, staticRenderFns } from "./wms.vue?vue&type=template&id=706c2fb5&scoped=true&"
import script from "./wms.vue?vue&type=script&lang=js&"
export * from "./wms.vue?vue&type=script&lang=js&"
import style0 from "./wms.vue?vue&type=style&index=0&id=706c2fb5&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "706c2fb5",
  null
  
)

export default component.exports